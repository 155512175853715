<template>
  <div id="home" :class="{'sidebar-open': sidebar || accountSettingsVisible}">

    <!-- Main Sidebar -->
    <main-sidebar
      :visible="mainSidebarVisible"
      @close="setSidebarState(false)"
    />
    <!-- /Main Sidebar -->

    <!-- Router View -->
    <div class="page-content">
      <!-- Free Trial Banner -->
      <ib-free-trial-banner v-if="isRoleOwner" />
      <!-- /Free Trial Banner -->

      <!-- Main Topbar -->
      <!--      <main-topbar />-->
      <div id="main-topbar" class="d-flex">
        <div class="container-1200 p-0 p-sm-3 d-flex justify-content-between align-items-center w-100">
          <mobile-top-bar ref="mobileTopBar" v-on-click-away="onClickAway" class="hidden-md-and-up" />
        </div>
      </div>
      <!-- /Main Topbar -->
      <router-view />
    </div>
    <!-- /Router View -->

    <!-- Global Dialogs -->

    <!-- Account Settings -->
    <account-settings
      :visible="accountSettingsVisible"
      @close="setAccountSettingsState(false)"
    />
    <!-- /Account Settings -->

    <!-- Dialog Idea Settings -->
    <dialog-idea-settings
      :visible="dialogIdeaSettingsVisible"
      :category="$store.state.idea.catalogueCategory"
      :idea="$store.state.idea.catalogueIdea || {}"
      :update-idea-settings="true"
      @close-dialog="onCloseDialogIdeaSettings"
      @idea-saved="onCloseDialogIdeaSettings"
    />
    <!-- /Dialog Idea Settings -->

    <!-- Dialog Upgrade -->
    <dialog-upgrade
      :dialog-upgrade-visible="dialogUpgradeVisible"
      @close="onCloseDialogUpgrade"
    />
    <!-- /Dialog Upgrade -->

    <!-- Dialog Invite Collaborator -->
    <dialog-invite-collaborator
      :visible="dialogUserInviteVisible"
      :role="dialogUserInviteRole"
      @close="onCloseDialogUserInvite"
    />
    <!-- /Dialog Invite Collaborator -->

    <!-- Dialog Payment History -->
    <dialog-payment-history
      :dialog-payment-history-visible="dialogPaymentHistoryVisible"
      @close-dialog="onCloseDialogPaymentHistory"
    />
    <!-- /Dialog Payment History -->

    <!-- Dialog Logout -->
    <dialog-logout
      :visible="dialogLogoutVisible"
      @close="onCloseDialogLogout"
    />
    <!-- /Dialog Logout -->

    <!-- Dialog Add Feedback -->
    <dialog-add-feedback
      :visible="dialogFeedbackVisible"
      @close="onCloseDialogFeedback"
    />
    <!-- /Dialog Add Feedback -->

    <!-- Dialog Change Billing -->
    <dialog-change-billing
      :visible="dialogChangeBillingVisible"
      @close="setDialogChangeBillingState(false)"
    />
    <!-- /Dialog Change Billing -->

    <!-- Dialog Ai Assistance -->
    <dialog-ai-assistance
      :visible="dialogAiAssistanceVisible"
      @close="setDialogAiAssistanceState(false)"
    />
    <!-- /Dialog Ai Assistance -->

    <!-- Dialog Idea Cover -->
    <dialog-idea-cover
      :visible="dialogIdeaCoverVisible"
      :category="$store.state.idea.catalogueCategory"
      :idea="$store.state.idea.catalogueIdea || {}"
      update-idea-settings
      @close-dialog="setDialogIdeaCoverState(false)"
    />
    <!-- /Dialog Idea Cover -->

    <!-- /Global Dialogs -->
  </div>
</template>

<script>
import AccountSettings from '@/views/Home/Components/RightSidebar/AccountSettings'
import DialogAddFeedback from '@/views/Home/Components/Dialogs/DialogAddFeedback'
import DialogAiAssistance from '@/views/Home/Components/Dialogs/DialogAiAssistance.vue'
import DialogChangeBilling from '@/views/Home/Components/Dialogs/DialogChangeBilling'
import DialogIdeaSettings from '@/views/SelectIdea/Dialogs/DialogIdeaSettings'
import DialogInviteCollaborator from '@/views/Home/InvitedUsers/Dialogs/DialogInviteCollaborator'
import DialogLogout from '@/views/Home/Components/Dialogs/DialogLogout'
import DialogPaymentHistory from '@/views/Home/Components/Dialogs/DialogPaymentHistory'
import DialogUpgrade from '@/views/Home/StoryMode/Components/Dialogs/DialogUpgrade'
import IbFreeTrialBanner from '@/components/_v2/FreeTrial/IbFreeTrialBanner'
import MainSidebar from '@/views/Home/Components/MainSidebar/MainSidebar'
import MobileTopBar from '@/views/Home/Components/MobileTopBar/MobileTopBar.vue'
// import MainTopbar from '@/views/Home/Components/MainTopbar'
import DialogIdeaCover from '@/views/SelectIdea/Dialogs/DialogIdeaCover.vue'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import { directive as onClickAway } from 'vue-clickaway'

import { mapMutations, mapState } from 'vuex'

export default {
  name: 'Home',

  directives: {
    onClickAway
  },

  components: {
    DialogIdeaCover,
    DialogAiAssistance,
    AccountSettings,
    DialogAddFeedback,
    DialogChangeBilling,
    DialogIdeaSettings,
    DialogInviteCollaborator,
    DialogLogout,
    DialogPaymentHistory,
    DialogUpgrade,
    IbFreeTrialBanner,
    MainSidebar,
    // MainTopbar,
    MobileTopBar
  },

  mixins: [MixinIdeaRoles],

  computed: {
    ...mapState('ui', [
      'accountSettings',
      'dialogs',
      'sidebar'
    ]),

    dialogAiAssistanceVisible () {
      return this.dialogs.aiAssistance
    },

    accountSettingsVisible () {
      return this.accountSettings
    },

    dialogIdeaSettingsVisible () {
      return this.dialogs.ideaSettings
    },

    dialogIdeaCoverVisible () {
      return this.dialogs.ideaCover
    },

    dialogUpgradeVisible () {
      return this.dialogs.upgrade
    },

    dialogUserInviteVisible () {
      return this.dialogs.userInvite.visible
    },

    dialogUserInviteRole () {
      return this.dialogs.userInvite.role
    },

    dialogPaymentHistoryVisible () {
      return this.dialogs.paymentHistory
    },

    dialogLogoutVisible () {
      return this.dialogs.logout
    },

    dialogFeedbackVisible () {
      return this.dialogs.feedback
    },

    dialogChangeBillingVisible () {
      return this.dialogs.changeBilling
    },

    mainSidebarVisible () {
      return this.sidebar
    }
  },

  methods: {
    ...mapMutations('ui', [
      'setAccountSettingsState',
      'setDialogPaymentHistoryState',
      'setDialogIdeaSettingsState',
      'setDialogUpgradeState',
      'setDialogUserInviteClosed',
      'setDialogLogoutState',
      'setDialogFeedbackState',
      'setDialogChangeBillingState',
      'setSidebarState',
      'setDialogAiAssistanceState',
      'setDialogIdeaCoverState'
    ]),

    onCloseDialogIdeaSettings () {
      this.setDialogIdeaSettingsState(false)
    },

    onCloseDialogUpgrade () {
      this.setDialogUpgradeState(false)
    },

    onCloseDialogUserInvite () {
      this.setDialogUserInviteClosed()
    },

    onCloseDialogPaymentHistory () {
      this.setDialogPaymentHistoryState(false)
    },

    onCloseDialogLogout () {
      this.setDialogLogoutState(false)
    },

    onCloseDialogFeedback () {
      this.setDialogFeedbackState(false)
    },

    onClickAway () {
      this.$refs.mobileTopBar.$refs.mobileTopBarDropdown.$refs.mobileTopBarDropdown.close()
    }
  }
}
</script>

<style lang="scss" scoped>
  #home {
    display: flex;
    background-color: #f5f6fa;
    overflow: hidden;

    @include media-breakpoint-up($md) {
      height: 100%;
    }
  }

  .sidebar-open {
    height: 100%;
  }
</style>
