<template>
  <div ref="ideaDetails" class="idea-details">
    <div
      v-if="isIdeaSelected"
      :class="{ 'idea-edit': canEdit && isIdeaSelected }"
      @click="$emit('click')"
    >

      <!-- Illustration -->
      <div
        v-if="idea.ideaSettings.image"
        class="illustration-wrapper"
        :class="{'collapse': isMenuCollapsed}"
        :style="cardImage"
      />
      <div
        v-else-if="idea.catalogueIdea"
        class="illustration-wrapper"
        :class="{'collapse': isMenuCollapsed}"
        :style="{ backgroundImage: (idea.catalogueIdea !== null && idea.catalogueIdea.cover) ? `url('${baseUrl}${idea.catalogueIdea.cover.path}${idea.catalogueIdea.cover.filename}')` : null }"
      >
        <div
          class="icon-wrapper hidden-md-only"
          :class="{'collapse': isMenuCollapsed}"
          :style="{ backgroundImage: (idea.catalogueIdea !== null && idea.catalogueIdea.icon) ? `url('${baseUrl}${idea.catalogueIdea.icon.path}${idea.catalogueIdea.icon.filename}')` : null }"
        />
      </div>
      <div
        v-else
        class="illustration-wrapper"
        :class="{'collapse': isMenuCollapsed}"
        :style="{ backgroundImage: (idea.catalogueCategory !== null && idea.catalogueCategory.cover) ? `url('${baseUrl}${idea.catalogueCategory.cover.path}${idea.catalogueCategory.cover.filename}')` : null }"
      >
        <div
          class="icon-wrapper hidden-md-only"
          :style="{ backgroundImage: (idea.catalogueCategory !== null && idea.catalogueCategory.icon) ? `url('${baseUrl}${idea.catalogueCategory.icon.path}${idea.catalogueCategory.icon.filename}')` : null }"
        />
      </div>
      <!-- /Illustration -->

      <!-- Idea Details -->
      <div class="idea-specs-wrapper" :class="{'menu-collapsed':isMenuCollapsed }">
        <div
          class="idea-specs hidden-md-only"
          :class="{'collapse': isMenuCollapsed}"
        >
          <!-- Idea Name -->
          <p class="name">
            {{ idea.name }}
          </p>
          <!-- /Idea Name -->

          <!-- Category -->
          <p class="industry">
            {{ idea.catalogueIdea ? idea.catalogueIdea.name : idea.catalogueCategory.name }}
          </p>
          <!-- /Category -->
        </div>
        <div class="idea-specs-icon">
          <i class="ib-icon ib-icon-settings h4" />
        </div>
      </div>
      <!-- /Idea Details -->
    </div>
    <div v-else class="idea-not-selected">
      {{ $t('sidebar.noSelectedIdea') }}
    </div>
  </div>
</template>

<script>
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import { getImageBySize } from '@/helpers/imageSizeHelper'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'MainSidebarIdeaDetails',

  mixins: [
    MixinIdeaRoles
  ],

  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      dialogIdeaSettingsVisible: false
    }
  },

  computed: {
    ...mapState({
      idea: state => state.idea
    }),
    ...mapGetters('idea', [
      'isIdeaSelected'
    ]),
    ...mapGetters('theme', ['isMenuCollapsed']),

    cardImage () {
      const img = this.idea.ideaSettings ? (this.idea.ideaSettings.image ? getImageBySize(this.idea.ideaSettings.image.sizes, 'medium') : null) : null

      return img ? 'background-image: url(' + img + ')' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.idea-edit {
  transition: all 0.25s;
  border: 1px solid transparent;
  position: relative;

  &:hover {
    cursor: pointer;
    transition: all 0.25s;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  }
}

.idea-not-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  color: silver;
  min-height: 220px;
}

.idea-specs-wrapper {
  padding: 12px 10px;
  display: flex;
  justify-content: space-between;
  transition: all 0.25s;

  &:hover {
    background-color: $color-blue-hover;

    .name, .industry, .idea-specs-icon {
      color: #0b42b0;
    }
  }

  &.menu-collapsed {
    justify-content: center;
    padding: 0 10px 12px;
  }

  @include media-breakpoint-down($lg) {
    justify-content: center;
    padding: 0 10px 12px;
  }

  .idea-specs-icon {
    color: #909399;
    padding: 4px;
    opacity: 0.4;
  }
}

.idea-specs {

  &.collapse {
    display: none;
  }

  p {
    margin: 0 auto;
  }

  .name {
    font-family: outfit-regular;
    font-size: 16px;
    margin-bottom: 0;
  }

  .industry {
    font-family: outfit-regular;
    font-size: 12px;
    opacity: 0.5;
    margin-top: -1px;
    margin-bottom: 10px;
  }
}

.idea-details {
  border-top: 1px solid rgba(0, 0, 0, 0.14);

  .el-menu-item {
    border-top: 1px solid rgba(0, 0, 0, 0.14);
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  }

  .illustration-wrapper {
    display: block;
    min-height: 200px;
    max-height: 200px;
    width: 100%;
    height: auto;
    background-image: asset("img/illustrations/seafood.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;

    &.collapse {
      min-height: 130px;
      max-height: 130px;
    }

    @include media-breakpoint-between($md, $lg) {
      min-height: 130px;
      max-height: 130px;
    }

    .icon-wrapper {
      position: absolute;
      z-index: 2;
      left: 5px;
      bottom: 0;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-color: $color-white;
      background-image: asset("img/idea-icons/fish-icon.svg");
      background-size: 26px;
      background-position: center;
      background-repeat: no-repeat;

      &.collapse {
        display: none;
      }

      @include media-breakpoint-up($md) {
        width: 50px;
        height: 50px;
        background-size: 40px;
      }
    }
  }
}
</style>
