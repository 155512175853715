<template>
  <!-- Dialog Add Feedback -->
  <ib-dialog
    id="dialog-add-feedback"
    :visible="visible"
    closable
    @close="closeDialogAddFeedback"
  >
    <!-- Dialog Title -->
    <template #title>
      <h2>
        {{ $t('dialogs.feedback.title') }}
        <span>
          <ib-tooltip class="item" :content="$t('dialogs.feedback.titleTooltip')">
            <i class="el-icon-question" />
          </ib-tooltip>
        </span>
      </h2>
    </template>
    <!-- /Dialog Title -->

    <!-- Dialog Content -->
    <div class="feedback-form-inner">
      <!-- Form -->
      <el-form ref="form" :model="form" @submit.native.prevent>
        <!-- Title -->
        <el-form-item
          class="input-inside-label"
          :class="{ 'focused': focus.title }"
        >
          <el-input
            v-model="form.title"
            :placeholder="$t('fields.title')"
            @focus="toggleFocus('title')"
            @blur="toggleFocus('title')"
          />
        </el-form-item>
        <!-- /Title -->

        <!-- Description -->
        <el-form-item>
          <el-input
            v-model="form.description"
            type="textarea"
            :rows="4"
            class=""
            :placeholder="$t('fields.description')"
            resize="none"
          />
        </el-form-item>
        <!-- /Description -->
      </el-form>
      <!-- /Form -->
    </div>
    <!-- /Dialog Content -->

    <!-- Dialog Footer -->
    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        space-between-buttons
        :submit-btn-text="$t('dialogs.feedback.submit')"
        :cancel-btn-text="$t('discard')"
        :disable-submit-btn="!isAddEnabled"
        :loading="loading"
        @submit="saveFeedback"
        @cancel="closeDialogAddFeedback"
      />
    </template>
    <!-- /Dialog Footer -->
  </ib-dialog>
  <!-- /Dialog Add Feedback -->
</template>

<script>
export default {
  name: 'DialogAddFeedback',

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loading: false,
      form: {
        id: 0,
        title: '',
        description: ''
      },
      focus: {
        title: false
      }
    }
  },

  computed: {
    isAddEnabled () {
      return this.form.title && this.form.description
    }
  },

  methods: {
    closeDialogAddFeedback () {
      this.$emit('close')
      this.resetForm()
    },

    saveFeedback () {
      this.loading = true

      this.form.store = this.$store.state
      this.form.route = this.$route.name
      this.form.routeUrl = window.location.href

      this.$http.post('story-mode/common/feedback', this.form)
        .then((response) => {
          if (response.status === 200) {
            this.resetForm()
            this.closeDialogAddFeedback()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]

      if (this.form[input]) {
        this.focus[input] = true
      }
    },

    resetForm () {
      this.form = {
        id: 0,
        title: '',
        description: ''
      }
      this.focus = {
        title: false
      }
    }
  }
}
</script>
